<template>
  <div class="layout">
    <div id="payment-wrapper" class="" style="position: relative">
      <div id="header" class="header">
        <a id="Header_HomeLogo_A" href="javascrip:void(0)" class="logo_game">
          <img :src="gameData.game_icon" alt="" />
        </a>
        <div class="right-group">
          <div class="right-header">
            <!-- <a v-if="selectServe" @click="gateDialog()">Gate</a> -->
            <template>
              <a
                v-for="item in gateData.channel"
                :key="item.name"
                @click="gateDialog(item)"
              >
                {{ item.name }}</a
              >
            </template>
            <div class="mobile-menu" v-if="$isMobile">
              <button
                class="shape-menu"
                :class="{ active: showMenu }"
                @click="handleClick()"
              >
                <span></span>
                <span></span>
                <span></span>
                <div class="rightPanel-background"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="StepIndicator" class="stepIndicator">
        <div class="stepIndicator_title">
          <a :href="autoLogin">{{ $t("consume.autoLogin") }}</a>
        </div>
        <!-- <el-select
          v-model="value"
          :popper-append-to-body="false"
          class="stepIndicator__select"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
            @click.native="scrollTo(item)"
          >
          </el-option>
        </el-select> -->
      </div>
      <div style="margin-top: 0px">
        <div class="container">
          <!-- 选择支付方式 -->
          <div class="content content_1" ref="country" id="country">
            <div class="content-box">
              <div class="content-box__header">
                <div>
                  <span class="content-box__header-number">1</span>
                  <span class="content-box__header-content">
                    {{ $t("consume.chooseCountry") }}
                  </span>
                </div>
              </div>
              <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="">
                  <el-select
                    style="width: 100%"
                    :popper-append-to-body="false"
                    :no-data-text="$t('consume.noData')"
                    :no-match-text="$t('consume.noData')"
                    v-model="form.country"
                    filterable
                    :placeholder="$t('consume.chooseCountry')"
                    @change="handleChangeCountry()"
                  >
                    <el-option
                      :label="item.label"
                      :value="item.value"
                      v-for="item in country"
                      :key="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    style="
                      width: 100%;
                      background: rgb(17, 136, 255);
                      border-color: transparent;
                    "
                    :style="{
                      background: form.country ? 'rgb(17,136,255)' : '#ebebeb',
                    }"
                    :disabled="!form.country"
                    @click="submitCountry()"
                    >{{ $t("Login.confirm") }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 选择支付方式 -->
          <div class="content content_1" v-if="showCountry" ref="pay" id="pay">
            <div class="content-box">
              <div class="content-box__header">
                <div>
                  <span class="content-box__header-number">2</span>
                  <span class="content-box__header-content">
                    {{ $t("consume.choosePayMethod") }}
                  </span>
                </div>
              </div>
              <el-checkbox-group :value="checkList">
                <div class="content-box__content">
                  <el-collapse
                    v-model="activeNames"
                    @change="handleCheck"
                    accordion
                  >
                    <el-collapse-item
                      :title="item.title"
                      :name="item.title"
                      v-for="(item, index) in productDetail.data.newAtm"
                      :key="index"
                    >
                      <template slot="title">
                        <div class="pmt-group">
                          <!-- <div class="pmt-group__img zalopay-icon-small">
                        </div> -->
                          <!-- <div
                            class="pmt-group__img"
                            :class="'pmt-group__img' + index"
                          /> -->
                          <div class="pmt-group__name">
                            <div class="displayName">
                              <p>
                                {{ item.title }}
                                <span v-if="item.title === 'Coin'">
                                  ({{ $t("consume.remaining") }} :
                                  {{ gateData.coin }})
                                </span>
                                <span v-if="item.hot === 1" style="color: red">
                                  <img
                                    style="width: 20px"
                                    src="@/assets/fire.png"
                                    alt=""
                                  />
                                </span>
                              </p>
                              <!-- <p class="minAmount">{{ item.need_pay }} VND</p> -->
                              <p class="minAmount">
                                {{ item.showMoney || item.need_pay }}
                                {{ item.showMoney ? "" : "Coin" }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div @click.stop>
                          <el-checkbox
                            class="check_box"
                            :label="item.title"
                            @change="handleCheck(item.title)"
                            :disabled="item.atm[0].disabled"
                            >&nbsp;</el-checkbox
                          >
                        </div>
                      </template>
                      <div
                        class="el-row"
                        style="margin-left: -5px; margin-right: -5px"
                      >
                        <div
                          class="el-col el-col-8"
                          :class="{ 'el-col-24': $isMobile }"
                          style="padding-left: 5px; padding-right: 5px"
                          @click="chooseCoupon(i, ind, item)"
                          v-for="(i, ind) in item.atm"
                          :key="ind"
                        >
                          <div
                            class="pmt-method"
                            :class="{
                              selected:
                                couponActive === ind && i.name === couponName,
                            }"
                          >
                            <span class="pmt-method__info">
                              <!-- <div
                              class="pmt__img payzing card partner-1 icon-1"
                            ></div> -->
                              <span class="pmt__cost">
                                <span v-if="i.name === 'Coin'">
                                  {{ gateData.coin }}
                                </span>
                                <!-- <div
                                class="_coupon_item_explain"
                                v-else-if="i.disabled"
                                style="
                                  display: 'block';
                                  top: calc(10%);
                                  right: 75px;
                                "
                              ></div> -->
                                <span v-else>
                                  {{ $t("consume.extraGift") }}
                                  {{ i.give_coin || "0" }}
                                </span>
                              </span>
                            </span>
                            <div class="pmt-method__name">
                              <span>{{ i.name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                  <el-button
                    type="primary"
                    style="
                      width: 100%;
                      background: rgba(17, 136, 255);
                      border-color: transparent;
                      margin-top: 10px;
                    "
                    v-if="
                      this.couponName !== null && this.couponActive !== null
                    "
                    @click="chooseCouponNext()"
                    >{{ $t("consume.next") }}</el-button
                  >
                </div>
              </el-checkbox-group>
            </div>
          </div>

          <!-- 商品信息 -->
          <div
            class="content"
            v-if="showGoodsInfo"
            ref="goodsInfo"
            id="goodsInfo"
          >
            <div class="content-box">
              <div class="content-box__header">
                <div>
                  <span class="content-box__header-number">3</span>
                  <span class="content-box__header-content">
                    {{ $t("consume.confirmInfo") }}
                  </span>
                </div>
              </div>
              <div class="content-box__content">
                <!-- <h2 class="list-title">{{ goodsInfo.product_info }}</h2> -->
                <div class="list-info">
                  <div class="info-detail">
                    <p>
                      <span>{{ $t("consume.Server") }}</span>
                      <code class="serverName">
                        {{ productDetail.data.cp_sname }}
                      </code>
                    </p>
                    <p>
                      <span>{{ $t("consume.roleId") }}</span>
                      <code class="roleId">
                        {{ productDetail.data.role_info.id }}
                      </code>
                    </p>
                    <p>
                      <span>{{ $t("consume.roleName") }}</span>
                      <code class="roleName">{{
                        productDetail.data.role_info.value
                      }}</code>
                    </p>
                    <p>
                      <span>{{ $t("consume.pay") }}</span>
                      <code class="pmtMethod">{{ goodsInfo.name }}</code>
                    </p>
                    <p v-if="goodsInfo.name !== 'Coin'">
                      <span>{{ $t("consume.selectCoupon") }}</span>
                      <code
                        class="pmtMethod choose_coupon"
                        @click="openCouponDialog"
                      >
                        <span
                          class="coupon_title"
                          v-if="goodsInfo.coupon_title"
                        >
                          {{ goodsInfo.coupon_title }}
                        </span>
                        <span v-else-if="useCouponNum > 0">
                          {{
                            $t("consume.canUseCoupon", { num: useCouponNum })
                          }}
                        </span>
                        <span v-else style="color: grey"
                          >{{ $t("consume.notUse") }}
                        </span>
                        <i class="el-icon-arrow-right"></i>
                      </code>
                    </p>
                    <p>
                      <span>{{ $t("consume.price") }}</span>
                      <code class="pmtMethod">
                        <span v-if="goodsInfo.name === 'Coin'"
                          >{{ Number(goodsInfo.order_amt_platform) }} Coin</span
                        >
                        <span
                          v-else-if="goodsInfo.coupon_type === '3'"
                          class="description_reduce"
                        >
                          <span>{{ Number(goodsInfo.order_amt) }} </span>
                          {{ Number(goodsInfo.coupon_discount_amount)
                          }}{{ goodsInfo.price_code_country }}
                          <div class="more">
                            ({{ $t("consume.extraGift") }}
                            <a @click="openMoreDialog(goodsInfo)">
                              {{ Number(goodsInfo.total_give) }}</a
                            >
                            <!-- <div class="_coupon_item_explain"></div> -->
                            )
                          </div>
                        </span>
                        <span v-else class="description_reduce">
                          {{ Number(goodsInfo.order_amt) }}
                          {{ goodsInfo.price_code_country }}
                          <div class="more">
                            ({{ $t("consume.extraGift") }}
                            <a @click="openMoreDialog(goodsInfo)">
                              {{ Number(goodsInfo.total_give) }} </a
                            >)
                            <!-- <div class="_coupon_item_explain"></div> -->
                          </div>
                        </span>
                      </code>
                    </p>
                  </div>
                </div>
                <!-- <p class="confirmPolicy" v-if="goodsInfo.product_info">
                  Chi tiết sản phẩm ：{{ goodsInfo.product_info }}
                </p> -->
                <p class="confirmPolicy">
                  {{ $t("consume.btnRemark") }}
                </p>
                <div
                  class="free_chance_color"
                  v-if="goodsInfo.rebate === 1 && free_chance > 0"
                >
                  {{ $t("consume.limitedTimeEvent", { num: free_chance }) }}
                </div>
                <el-button
                  type="primary"
                  style="
                    width: 100%;
                    background: rgb(17, 136, 255);
                    border-color: transparent;
                  "
                  @click="handlePay()"
                  >{{ $t("Login.confirm") }}</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-menu
      v-show="$isMobile && showMenu"
      class="el-menu-vertical-demo menu"
      :class="{ closemenu: !showMenu }"
    >
      <el-menu-item
        index="item.name"
        @click="gateDialog(item)"
        v-for="item in gateData.channel"
        :key="item.name"
      >
        <span>{{ item.name }}</span>
      </el-menu-item>
    </el-menu>
    <Loading1 :show="showLoading" :backgroundColor="false" />
    <More ref="more" />
    <Coupon ref="showCoupon" />
    <Gate ref="Gate" />
    <GiftCode ref="giftCode" />
    <Footer ref="footer" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import Loading1 from "../../components/Loading1";
import Footer from "../../components/Footer";
import More from "../../components/more.vue";
import Gate from "../../components/Gate";
import GiftCode from "../../components/giftCode.vue";
import Coupon from "../../components/PurchaseCoupon.vue";
export default {
  name: "",
  mixins: [],
  components: {
    Footer,
    Loading1,
    More,
    Gate,
    GiftCode,
    Coupon,
  },
  props: {},
  data() {
    return {
      country: [
        {
          value: "VN",
          label: "Việt Nam",
        },
        {
          value: "ID",
          label: "Indonesia",
        },
        {
          value: "PH",
          label: "Philippines",
        },
        {
          value: "TH",
          label: "ประเทศไทย",
        },
      ],
      form: {
        country: "",
      },
      showCountry: false,
      checkList: [],
      imgTitle: {
        CoinPay: 0,
        MOMO: 1,
        BankQR: 2,
        ATM: 3,
        VISA: 4,
      },
      payData: {
        cp_sid: "", // 区服id
        cp_roleid: "", // 角色id
        product_id: "", // 商品id
        pay_type: "", // 支付类型
        channel: "", // 支付渠道
        user_coupon_id: "", //优惠券id
      },
      routeData: "",
      showMenu: false, //展示菜单栏
      showAdvert: false, //展示客服
      selectServe: false, //选择区服
      servers_roles: {
        servers: [],
        roles: [],
      }, //区服角色
      showRolesInfo: {}, //页面展示角色
      showServe: false, //展示区服
      showLoading: false, //loading状态
      showPay: false, //展示支付方式
      showCoupon: false, //展示优惠券
      channelShow: false, //优惠券使用详情下拉
      checkNum: null, //优惠券被选中
      couponActive: null, //支付方式被选中
      showGoodsInfo: false, //展示商品信息
      activeNames: [], //默认下拉
      couponName: "", //选中的支付方式名字
      value: "1", //下拉选项
      productDetail: {
        data: { atm: [] },
      },
      free_chance: 0, //免单次数
      useCouponNum: 0, //可用x张优惠券
      goodsInfo: {
        name: "", //支付方式名称
        order_amt: "", //价格
        coupon_discount_amount: "", //优惠券满减
        coupon_reduce_amt: "", //扣完优惠券后的价格
        order_amt_platform: "", //coin价格
        total_give: "", //所有优惠加起来的数目
        give_coin: "", //支付方式给的优惠
        level: "", //Vip等级
        extra_coin: "", //税率
        extra_coin_num: "", //vip送的优惠
        coupon_give_coin: "", //优惠券送的优惠
        coupon_title: "", //优惠券名称
        coupon_type: "", //优惠券类型
        product_img: "", //产品图片
        product_info: "", //产品名称
        rebate: 0, //渠道返利
        checkNum: null, //优惠券索引
        coupon_info: {}, //优惠券信息
      },
      spareGoodsInfo: {},
      gateData: {}, //gate相关数据
      gameData: {}, //游戏点击进来的数据
      checked: [false, true, false], //点击支付方式
      standbyName: null, //备用支付方式索引值
      autoLogin: "",
    };
  },
  computed: {
    options() {
      return [
        {
          value: "1",
          label: this.$t("consume.step2_1"),
          name: "country",
          disabled: false,
        },
        {
          value: "2",
          label: this.$t("consume.step3_1"),
          name: "pay",
          disabled: false,
        },
        {
          value: "3",
          label: this.$t("consume.step4_1"),
          name: "goodsInfo",
          disabled: true,
        },
      ];
    },
  },
  watch: {},
  created() {},
  mounted() {
    // this.loading();
    this.routeData = this.$route.query.data;
    // this.$i18n.locale =localStorage.getItem("locale") || "PH";
    // this.getGateData();
    this.getLanguageAndCountry();
  },
  methods: {
    //进入页面时loading
    loading() {
      this.showLoading = false;
      setTimeout(() => {
        this.showLoading = false;
      }, 3000);
    },
    // 点击跳转
    scrollTo(item) {
      if (item.disabled) {
        return;
      }
      this.$nextTick(() => {
        let scrollTo = this.$refs[item.name];
        scrollTo.scrollIntoView({ block: "start" });
      });
    },
    // 监听页面滚动事件
    handleScroll() {
      window.addEventListener("scroll", this._.debounce(this.scrolling, 50));
    },
    scrolling() {
      let win = window.pageYOffset;
      // const info = this.$refs.info.offsetTop;
      const pay = this.$refs.pay.offsetTop;
      if (!this.$refs.pay) {
        return;
      }
      let goodsInfo;

      if (this.showCoupon) {
        goodsInfo = this.$refs.goodsInfo.offsetTop;
      }
      if (win < pay) {
        this.value = "1";
      } else if (win > pay && win < goodsInfo) {
        this.value = "2";
      } else {
        this.value = "1";
      }
    },
    //打开礼包码弹窗
    openGiftCodeDialog() {
      if (this.goodsInfo.name === "Coin") {
        return;
      }
      this.$refs.giftCode.dialogFormVisible = true;
    },
    //打开优惠券弹框
    openCouponDialog() {
      this.$refs.showCoupon.checkNum = this.goodsInfo.checkNum;
      if (this.goodsInfo.checkNum !== null) {
        //填入值，若存在索引则覆盖，否则将清空索引
        this.$refs.showCoupon.productDetail.data.coupon[
          this.goodsInfo.checkNum
        ].check = true;
      } else {
        this.$refs.showCoupon.productDetail.data.coupon.forEach((item) => {
          item.check = false;
        });
      }
      this.$refs.showCoupon.dialogFormVisible = true;
      this.$handlePadding();
    },
    //打开支付额外金额获取流水弹窗
    openMoreDialog(goodsInfo) {
      this.$refs.more.dialogFormVisible = true;
      this.$refs.more.goodsInfo = goodsInfo;
    },
    //打开充值弹窗
    gateDialog(item) {
      if (item.name === "gate") {
        this.$refs.Gate.dialogFormVisible = true;
      } else {
        // window.location.href =
        //   "http://192.168.110.215:8080/apis" +
        //   item.url +
        //   "&token=" +
        //   localStorage.getItem("token");
        let params = {};
        this.$http
          .post(`/purchase/atmCardPay?country=${this.form.country}`, params)
          .then((res) => {
            if (res.data.code === 200) {
              window.location.href = res.data.data.redirect_url;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      }
    },
    //获取gate信息
    getGateData() {
      let params = {};
      this.$http
        .get(`/purchase/detail?country=${this.form.country}`, { params })
        .then((res) => {
          if (res.data.code === 200) {
            this.gateData = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //是否打开菜单
    handleClick() {
      this.showMenu = !this.showMenu;
    },
    //跳转页面
    toPage() {
      this.$router.push("/");
    },
    // 选择优惠券
    chooseCoupon(item, index, i) {
      if (i.title === "Cổng khác") {
        this.checkList = [];
      }

      //如果gate值不够提示余额不足
      // const arr = ["Coin", "MoMo", "ATMCARD"];
      const arr = ["Coin", "MoMo", "BankQR", "ATMCARD", "VISA"];
      if (item.name === "Coin" && this.gateData.coin < i.need_pay) {
        if (!arr.includes(this.couponName) && this.couponName) {
          this.activeNames = ["Cổng khác"];
        } else {
          this.checkList[0] = this.couponName;
        }
        this.$message.warning(this.$t("consume.insufficientBalance"));
        return;
      }
      if (item.disabled) {
        if (!arr.includes(this.couponName) && this.couponName) {
          this.activeNames = ["Cổng khác"];
        } else {
          this.checkList[0] = this.couponName;
        }
        if (item.maintain) {
          this.$message.warning(this.$t("consume.maintain"));
        } else {
          this.$message.warning(
            this.$t("consume.MinDeposit", { money: item.min })
          );
        }

        return;
      }
      this.showGoodsInfo = false;
      this.options[2].disabled = true;

      const { channel, pay_type } = item;
      this.payData.channel = channel;
      this.payData.pay_type = pay_type;
      // 每次选择支付方式前清空一遍数据
      this.goodsInfo = {
        name: "", //支付方式名称
        order_amt: "", //价格
        coupon_discount_amount: "", //优惠券满减
        coupon_reduce_amt: "", //扣完优惠券后的价格
        order_amt_platform: "", //coin价格
        total_give: "", //所有优惠加起来的数目
        give_coin: "", //支付方式给的优惠
        level: "", //Vip等级
        extra_coin: "", //税率
        extra_coin_num: "", //vip送的优惠
        coupon_give_coin: "", //优惠券送的优惠
        coupon_title: "", //优惠券名称
        coupon_type: "", //优惠券类型
        product_img: "", //产品图片
        product_info: "", //产品名称
        need_pay: "", //需要支付的方式
        checkNum: null, //优惠券索引
        coupon_info: {}, //优惠券信息
      };
      //填充数据
      const {
        order_amt,
        order_amt_country,
        order_amt_platform,
        price_code_country,
        level,
        extra_coin,
        product_img,
        product_info,
      } = this.productDetail.data;
      this.goodsInfo.name = item.name;
      this.goodsInfo.give_coin = item.give_coin || "";
      // this.goodsInfo.order_amt = order_amt; //网充限制档位修改
      this.goodsInfo.order_amt = order_amt_country;
      this.goodsInfo.price_code_country = price_code_country;
      this.goodsInfo.order_amt_platform = order_amt_platform;
      this.goodsInfo.level = level;
      this.goodsInfo.extra_coin = extra_coin;
      // this.goodsInfo.extra_coin_num = ((extra_coin / 100) * order_amt).toFixed(2);
      this.goodsInfo.extra_coin_num = extra_coin;
      this.goodsInfo.product_img = product_img;
      this.goodsInfo.product_info = product_info;
      this.goodsInfo.total_give = Number(
        Number(this.goodsInfo.give_coin) + Number(this.goodsInfo.extra_coin_num)
      ).toPrecision(2);
      this.spareGoodsInfo = JSON.parse(JSON.stringify(this.goodsInfo));
      this.goodsInfo.rebate = item.rebate;
      this.goodsInfo.need_pay = i.need_pay;
      //逻辑方面
      //选中支付方式
      if (this.couponName == item.name && this.couponActive == index) {
        this.couponName = null;
        this.couponActive = null;
        this.showCoupon = false;
        this.showGoodsInfo = false;
        this.options[2].disabled = true;
      } else {
        this.couponName = item.name;
        this.standbyName = item.name;
        this.$refs.showCoupon.couponName = item.name;
        this.couponActive = index;
      }
      this.useCouponNum = 0;
      this.productDetail.data.coupon.forEach((item) => {
        if (
          !(
            (!item.channel_type_arr.includes(this.couponName) &&
              item.title !== "Coin") ||
            item.limit_time > 0 ||
            (item.usage_num_daily > 0 && item.usage_num_daily_count <= 0)
          )
        ) {
          this.useCouponNum++;
        }
      });
    },
    _couponStartTiming(ts, key, item) {
      let start = setInterval(function () {
        ts = ts > 0 ? ts - 1 : 0;
        item.limit_time = ts;
        // this.getTimeS(ts);
        if (ts <= 0) {
          clearInterval(start);
        }
      }, 1000);
    },
    chooseCouponNext() {
      //如果gate值不够提示余额不足
      // let coin =
      //   Number(this.$store.state.gameData.coin) +
      //   Number(this.$store.state.gameData.bind_coin);
      // if (this.goodsInfo.name === "Coin" && coin < this.goodsInfo.need_pay) {
      //   this.$message.warning("Số dư không đủ");
      //   return;
      // }
      // if (this.goodsInfo.disabled) {
      //   this.$message.warning(` Mức nạp thấp nhất ${this.goodsInfo.min}`);
      //   return;
      // }
      // if (this.couponName == null && this.couponActive == null) {
      //   this.$message.warning(`hãy chọn lại phương thức thanh toán khác`);
      //   return;
      // }
      //显示方面
      if (this.checkNum !== null) {
        this.productDetail.data.coupon[this.checkNum].check = false;
        this.checkNum = null;
      }
      this.showCoupon = true;
      this.showGoodsInfo = true;
      this.options[2].disabled = false;
      this.$nextTick(() => {
        let goodsInfo = this.$refs.goodsInfo;
        goodsInfo.scrollIntoView({ block: "start" });
      });
    },
    //获取商品详细信息
    getProductDetail() {
      this.$http
        .post(`/purchase/detail?country=${this.form.country}`)
        .then((res) => {
          if (res.data.code === 200) {
            this.gateData = res.data.data;
            this.showPay = true;
            this.options[1].disabled = false;
            this.activeNames = []; //关闭
            this.couponActive = null;
            this.showCoupon = false;
            this.showGoodsInfo = false;
            this.showCountry = true;
            this.options[2].disabled = true;
            this.free_chance = res.data.data.free_chance;
            this.$nextTick(() => {
              let pay = this.$refs.pay;
              pay.scrollIntoView({ block: "start" });
            });
            this.productDetail = res.data;
            this.$refs.showCoupon.productDetail = res.data;
            const data = this.productDetail.data;
            let coin = [
              {
                title: "Coin",
                need_pay: data.order_amt_platform,
                atm: [
                  {
                    name: "Coin",
                    coin: data.coin,
                  },
                ],
              },
            ];
            let recommend_atm = [];
            data.recommend_atm.forEach((item) => {
              let showMoney = `${data.order_amt_country} ${data.price_code_country}`;
              let info = {
                need_pay: data.order_amt_country,
                hot: item.hot,
                showMoney: showMoney,
                title: item.name,
                atm: [item],
              };
              recommend_atm.push(info);
            });
            // let atm = {
            //   title: "Cổng khác",
            //   atm: data.atm,
            //   need_pay: data.order_amt,
            // };
            let newAtm = [];
            // newAtm.push(...coin, ...recommend_atm, atm);
            newAtm.push(...coin, ...recommend_atm);
            newAtm.forEach((item) => {
              if (item.title === "Coin") {
                return;
              }
              item.atm.forEach((i) => {
                i.disabled = i.min > data.order_amt_country ? true : false;
                i.disabled = i.maintain === 1;
              });
            });
            this.productDetail.data.newAtm = newAtm;
            //优惠券操作
            data.coupon.forEach((item, index) => {
              // 该券是否有 有效时间，没有就显示可用时间
              if (item.valid_start_time) {
                // 有效时间 (券领取后)
                item.coupon_date_text = 1;
                item.date_text = ` <div style="font-size: 12px;color: #83B8E7;">${item.valid_start_time}—${item.valid_end_time}</div>`;
              } else {
                // 可用时间 (未领取)
                if (item.get_start_time && item.get_end_time) {
                  item.coupon_date_text = 0;
                  item.date_text = `<div style="font-size: 12px;color: #83B8E7;">${item.get_start_time}—${item.get_end_time}</div>`;
                } else {
                  item.date_text = ``;
                }
              }

              // 处理 满赠/满减 信息;
              switch (item.type) {
                case "1": // 满赠 （固额）
                  item.give_coin_text = this.$t("consume.deliver1", {
                    money: item.order_coin,
                    gift: item.give_coin,
                  });
                  break;
                case "2": // 满赠 （比例）
                  item.give_coin_text = this.$t("consume.deliver2", {
                    money: item.order_coin,
                    gift: item.give_coin,
                  });
                  break;
                case "3": // 满减 （固额）
                  item.give_coin_text = this.$t("consume.deliver3", {
                    money: item.order_coin,
                    gift: item.give_coin,
                  });
                  break;
              }

              // 处理券的可用支付渠道信息展示
              item.channel_type_arr.forEach((child, index) => {
                if (index == 0) {
                  item.pay_type_text = `${child}`;
                } else {
                  item.pay_type_text = `${item.pay_type_text}, ${child}`;
                }
              });
              if (item.limit_time > 0) {
                this._couponStartTiming(item.limit_time, index, item);
              }
              // 优惠券的锁定时间（ps: 选择券下单，券会锁定10分钟不能使用）
              // if (item.limit_time > 0) {
              //   item.limit_time_text = `<div class="_coupon_box_timing">Đang Dùng <span class="_coupon_box_timing_val${index}">${this._getTimeS(
              //     item.limit_time
              //   )}</span></div>`;
              // } else {
              //   item.limit_time_text = "";
              // }
              item.check = false;
              item.channelShow = false;
            });
            // this.productDetail = res.data
            // console.log(res.data);
            //默认MOMO支付
            this.checkList = [];
            this.couponName = null;
            this.couponActive = null;
            this.handleCheck(recommend_atm[0].title);
          } else {
            // alert(window.location.href)
            // this.$message.error(window.location.href);
            this.$message.error(res.data.msg);
          }
        });
    },

    changeActive(e) {
      let atmData = this.productDetail.data;
      atmData.newAtm.forEach((item, index) => {
        if (item.title === e) {
          this.chooseCoupon(
            atmData.newAtm[index].atm[0],
            index,
            atmData.newAtm[index]
          );
        }
      });
    },

    handleCheck(e) {
      const item = this.productDetail.data.newAtm.find(
        (row) => row.title === e
      );
      let atmData = this.productDetail.data;
      atmData.newAtm.forEach((i, index) => {
        if (i.title == e) {
          this.handleCheckBox(item);
          this.activeNames = [];
          this.changeActive(item.title);
        }
      });
    },

    handleCheckBox(e) {
      let val = e.title;
      let item = e.atm[0];
      //如果gate值不够提示余额不足
      if (item.name === "Coin" && this.gateData.coin < e.need_pay) {
        return;
      }
      if (item.disabled) {
        return;
      }
      if (val === this.checkList[0]) {
        this.checkList = [];
      } else {
        this.checkList = [val];
      }
    },

    //获取区服接口
    getLanguageAndCountry() {
      this.$http.post("/purchase/init").then((res) => {
        if (res.data.code === 200) {
          let country = localStorage.getItem("country");
          let countryList = ["VN", "ID", "PH", "TH"];
          if (country) {
            this.form.country = country;
          } else {
            this.form.country = countryList.includes(res.data.data.country)
              ? res.data.data.country
              : "VN";
            localStorage.setItem("country", this.form.country);
          }
          this.autoLogin = res.data.data.auto_login_url;
          let language = localStorage.getItem("locale");
          let languageList = ["VI", "ID", "EN", "TH", "ZH"];
          let lang = languageList.includes(res.data.data.language)
            ? res.data.data.language
            : "EN";
          if (language) {
            this.$i18n.locale = language;
            localStorage.setItem("locale", language);
            this.$refs.footer.lang = language;
          } else {
            this.$i18n.locale = lang;
            localStorage.setItem("locale", lang);
            this.$refs.footer.lang = lang;
          }
          this.options[1].disabled = true;
        }
      });
    },
    //确认country数据
    submitCountry() {
      this.handleScroll();
      this.getProductDetail();
    },
    //修改数据
    handleChangeCountry() {
      this.showCountry = false;
      this.showGoodsInfo = false;
      this.options[1].disabled = true;
      this.options[2].disabled = true;
    },

    handlePay: debounce(function () {
      let params = this.payData;
      delete params.cp_sid;
      delete params.cp_roleid;
      delete params.product_id;
      let coin = Number(this.gateData.coin);
      if (
        this.goodsInfo.name === "Coin" &&
        this.goodsInfo.order_amt_platform > coin
      ) {
        this.$message.warning(this.$t("consume.insufficientBalance"));
        return;
      }
      this.$http
        .post(`/purchase/pay?country=${this.form.country}`, params)
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.redirect_url) {
              window.location.href = res.data.data.redirect_url;
            } else {
              // this.$message.error(res.data.msg);
              this.$alert(
                `<div class="alert-message">${this.$t(
                  "consume.paySuccess"
                )}</div>`,
                {
                  dangerouslyUseHTMLString: true,
                  confirmButtonText: this.$t("Info.cancel"),
                }
              );
              this.getGateData();
            }
            localStorage.setItem("country", this.form.country);
            localStorage.setItem("locale", this.$i18n.locale);
          } else {
            this.$message.error(res.data.msg);
          }
        });
    }, 1000),
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style scoped lang="scss">
@import "./css/pc.scss";
@import "./css/phone.scss";
/deep/ .el-form-item__content {
  margin-left: 0 !important;
}
/deep/ .el-popper {
  left: 0px !important;
}
/deep/ .popper__arrow {
  opacity: 0 !important;
}
</style>
<style>
.el-message-box {
  width: auto !important;
}
.alert-message {
  font-size: 18px;
}
@media screen and (max-width: 1000px) {
  .el-message-box {
    width: 75% !important;
  }
}
@media screen and (max-width: 368px) {
  .el-message {
    min-width: 255px;
  }
}
</style>